<template>
    <div>
        <b-row no-gutters>
            <b-col v-if="show">
                <b-col class="adscat_header">
                    <h1 v-if="category && category.name">{{category.name}}</h1>
                    <h1 v-else>Объявления</h1>                    
                </b-col>
                <div v-if="preLoad">
                    <b-card class="card">
                        <b-media>
                            <b-row class="item">
                                <b-col cols="12" class="user_head ">
                                    <div class="head_userpic">
                                        <div class="ads_icon userpic" :style="'background-image: url('+this.users[item.user_id].photo_250+')'"></div>
                                    </div>
                                    <p class="ads_name_header">
                                        <span class="ads_name">{{users[item.user_id].name}} {{users[item.user_id].f_name}}</span>
                                    </p>
                                    <p class="ads_name_info">
                                        <span class="ads_comment_date" v-html="$moment(item.created_at).local().format('DD.MM.YYYY HH:mm')"></span>
                                    </p>
                                    <b-button class="del_comment_btn" v-if="$user && item.user_id == $user.id" @click="del(item.id)">
                                        <font-awesome-icon :icon="['fa', 'times']" class="news_date_icon" />
                                    </b-button>
                                </b-col>
                                <b-col cols="12">
                                    <p class="ads_comment mb-3" v-html="this.text"></p>
                                    <imageLoader v-if="item.image" class="" :src="item.image"></imageLoader>
                                </b-col>
                            </b-row>
                        </b-media>
                    </b-card>
                    <Comments :url="url" :comments="comments" :users="users" class="mt-2"></Comments>
                    <div class="pagination">
                        <router-link v-if="prev" class="col but but-prev" :to="{params: { id:prev}}">Назад
                        </router-link>
                        <router-link v-if="next" class="col but but-next" :to="{params: { id:next }}">Следующее
                        </router-link>
                    </div>
                </div>
                <div v-else>
                    <PreLoadBox></PreLoadBox>
                </div>
            </b-col>
        </b-row>
        <AdsPlaginNew :direction="'horizontal'" class="main_plagin"/>
    </div>
</template>
<script>
import PreLoadBox from "../../../Preloader/coin";
import imageLoader from "@/components/ImageLoader/index";
import Comments from "@/components/Comments/index";

export default {
    name: "AdsGet",
    components: {
        PreLoadBox,
        Comments,
        imageLoader
    },
    metaInfo() {
        return {
            title: this.item && this.item.text ? `${this.$cutText(this.item.text,40)} - в городе ` : ( this.category && this.category.name ? `Объявления ${this.category.name} - ` : null),
            meta: [
                {name: 'description', itemprop: 'description', content: this.item && this.item.text && this.category && this.category.name ? `${this.item.text}. Объявления о  ${this.category.name} . В городе ${this.$myCity.name}.` : '' },
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                { property: 'og:title', content: this.category && this.category.name ? `Объявления ${this.category.name} - ${this.$myCity.name}` : '' },
                { property: 'og:description', content: this.item && this.item.text && this.category && this.category.name ? `${this.item.text}. Объявления о  ${this.category.name} . В городе ${this.$myCity.name}.` : '' },
                { property: 'og:image', content: 'https://okaygorod.com/images/logoHD.png' },
                { property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            category: null,
            preLoad: null,
            item: {},
            next: null,
            prev: null,
            comments: [],
            users: {},
            show: false,
            text: null,
        }
    },
    methods: {
        api() {
            this.$scrollToTop();
            this.$http.get(this.url).then((response) => {
                this.category = response.data.response.category;
                this.item = response.data.response.item;
                this.next = response.data.response.next;
                this.prev = response.data.response.prev;
                this.comments = response.data.response.comments;
                this.users = response.data.response.users;
                this.show = true;
                this.text = this.$htmContext(response.data.response.item.text);
                this.preLoad = 'finish';
            })
        },
        del(id) {
            let url = this.$config.api_url + this.$route.params.city + '/Ads.hide/' + id;
            this.$http.post(url).then((response) => {
                if (response.data.status === 'success') {
                    //this.$pageUpdate = 'reload';
                }
            })
        },
    },
    mounted() {
        this.api();
    },
    watch: {
        '$route.params.id': function() {
            this.api();
        }
    },
    computed: {
        url: {
            get: function() {
                return this.$config.api_url + this.$route.params.city + '/Ads.get/' + this.$route.params.id;
            }
        }
    }
}
</script>
<style scoped>
@media only screen and (max-width: 991px) {
    .right_menu {
        display: none;
    }
}

.con a {
    text-decoration: none;

}

.adscat_header {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 15px 0;
    text-transform: uppercase;
    margin-bottom: 15px;

}

.adscat_back {
    width: 75px;
    padding: 5px 5px;
    position: relative;
    background: #4a76a8;
    border-radius: 0.25rem;
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 14px;
    color: #fff;
}

.adscat_back:before {
    content: "";
    border: solid transparent;
    position: absolute;
    top: 5px;
    right: 100%;
    border-right-color: #4a76a8;
    border-width: 7px;
    margin-right: -1px;
}

.adscat_back a {
    text-decoration: none;
    color: #fff;
}

@media only screen and (max-width: 767px) {
    .ads_icon {
        width: 40px;
        height: 40px;
    }

    .ads_name_header {
        font-size: 14px;

    }
}

@media only screen and (min-width: 576px) {
    .item {
        padding: 0 16px 16px 16px;
    }
}

@media only screen and (min-width: 767px) {
    .ads_icon {
        width: 50px;
        height: 50px;
    }

    .ads_name_header {
        font-size: 18px;

    }
}

.card {
    border-radius: 12px;
    margin-top: 24px;
    overflow: hidden;
}

.card-body {
    padding: 8px;
}

.card-body p {
    margin-bottom: 0px;
}

.user_head {
    text-align: left;
    position: relative;
    z-index: 1;
    min-height: 77px;
    padding: 16px 18px 0 70px;

}

.head_userpic {
    position: absolute;
    top: 16px;
    left: 16px;
    text-decoration: none;
    font: 0/0 a;
}

.ads_icon {
    border-radius: 100%;
    position: relative;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    border: none;
    background-color: #fff;
    background-position: 50%;
    background-size: cover;
    font-size: 0;

}

.ads_icon:before {
    box-shadow: none;

}

.userpic:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, .5);
    border-radius: 100%;
}

.ads_name_header {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    margin-right: 70px;
    line-height: 20px;
    margin-left: 7px;
}

.ads_name {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    font-weight: 500;
}

.ads_name_info {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    flex-wrap: wrap;
    color: #939cb0;
    margin-right: 70px;
    font-size: 14px;
    line-height: 20px;
}

.ads_comment_date {

    font-size: 11px;
    color: #5d5d5f;
}

.ads_comments {
    float: right;
    color: #2c3e50;
    text-decoration: none;
    align-items: center;
}

.ads_comments:hover {
    color: #4a76a8;
}

.del_comment_btn {
    position: absolute;
    right: 16px;
    top: 16px;
    color: #2c3e50;
    background: transparent;
    border: none;
}

.del_comment_btn:hover {
    color: red;
    background: transparent;
    border: none;
}

.del_comment_btn:active {
    color: red;
    background: transparent;
    border-color: red;
}

.del_comment_btn:focus {
    color: red;
    background: transparent;

}

.del_comment_btn:not(:disabled):not(.disabled):active {
    color: red;
    background: transparent;
    border-color: red;
}

.comment_box {
    width: 100%;
}

.ads_img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 12px;

}

.ads_comment {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
}

.icon {
    height: 20px;

}

.comment_size {
    color: #a9b0c0;
    font-size: 18px;
    margin-left: 5px;
}

.ads_comments:hover .comment_size {
    color: #4a76a8;
}

.ads_comments svg {
    fill: #A9B0C0;
}

.ads_comments:hover svg {
    fill: #4a76a8;
}
</style>